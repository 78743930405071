import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

// Arrow Navigation Component
const ArrowButton = ({ direction, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    className={`absolute top-1/2 transform -translate-y-1/2 ${
      direction === 'left' ? '-left-16' : '-right-16'
    } bg-white p-4 rounded-full shadow-lg hover:shadow-xl z-10`}
    onClick={onClick}
    aria-label={`Navigate ${direction}`}
  >
    <svg
      className="w-6 h-6 text-gray-800"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d={direction === 'left' ? 'M15 19l-7-7 7-7' : 'M9 5l7 7-7 7'}
      />
    </svg>
  </motion.button>
);

const Experience = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [isInView, setIsInView] = useState(false);

  const autoplayDelay = 5000;
  const experiences = [
    {
      title: 'Senior Software Engineer',
      company: 'Tech Company',
      duration: '2021 - Present',
      description: [
        'Led development of core features resulting in 40% increase in user engagement',
        'Managed a team of 5 developers and implemented agile methodologies',
        'Architected and deployed microservices infrastructure',
      ],
      skills: ['React', 'Node.js', 'AWS', 'Docker'],
    },
    {
      title: 'Full Stack Developer',
      company: 'Digital Agency',
      duration: '2019 - 2021',
      description: [
        'Developed responsive web applications for diverse clients',
        'Optimized application performance, reducing load time by 60%',
        'Collaborated with UX team to implement modern design patterns',
      ],
      skills: ['JavaScript', 'Python', 'PostgreSQL', 'Redis'],
    },
    {
      title: 'Frontend Developer',
      company: 'Startup Inc.',
      duration: '2017 - 2019',
      description: [
        'Built interactive user interfaces using modern frameworks',
        'Implemented responsive designs and animations',
        'Integrated third-party APIs and services',
      ],
      skills: ['HTML/CSS', 'JavaScript', 'React', 'Vue.js'],
    },
  ];

  const paginate = (newDirection) => {
    let newIndex = currentIndex + newDirection;
    if (newIndex < 0) newIndex = experiences.length - 1;
    if (newIndex >= experiences.length) newIndex = 0;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const handleScroll = () => {
      const experienceSection = document.getElementById('experience');
      const rect = experienceSection.getBoundingClientRect();
      setIsInView(rect.top >= 0 && rect.bottom <= window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isInView) return;

    const timer = setInterval(() => {
      paginate(1);
    }, autoplayDelay);

    return () => clearInterval(timer);
  }, [isInView, currentIndex]);

  const openModal = (experience) => {
    setSelectedExperience(experience);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedExperience(null);
  };

  return (
    <section id="experience" className="py-2 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-8"
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Professional Experience
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            My journey in software development
          </p>
        </motion.div>

        <div
          className="relative h-[400px]"
          onMouseEnter={() => setIsModalOpen(false)}
        >
          <AnimatePresence initial={false}>
            <motion.div
              key={currentIndex}
              initial={{ x: 1000, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -1000, opacity: 0 }}
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              onClick={() => openModal(experiences[currentIndex])}
              className="absolute w-full cursor-pointer"
            >
              <div className="bg-white rounded-xl shadow-lg p-8 mx-4 md:mx-auto max-w-10xl">
                <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
                  <div>
                    <h3 className="text-2xl font-bold text-gray-900 mb-2">
                      {experiences[currentIndex].title}
                    </h3>
                    <p className="text-lg text-indigo-600 mb-2">
                      {experiences[currentIndex].company}
                    </p>
                  </div>
                  <p className="text-gray-600 font-medium">
                    {experiences[currentIndex].duration}
                  </p>
                </div>

                <motion.ul
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="list-none mb-6 space-y-2 text-gray-600"
                >
                  {experiences[currentIndex].description.map((item, idx) => (
                    <motion.li key={idx} className="text-base flex items-start">
                      <span className="mr-2">•</span>
                      <span>{item}</span>
                    </motion.li>
                  ))}
                </motion.ul>

                <div className="flex flex-wrap gap-2">
                  {experiences[currentIndex].skills.map((skill, idx) => (
                    <motion.span
                      key={idx}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      whileHover={{ scale: 1.1, rotate: 10 }}
                      className="px-3 py-1 bg-indigo-100 text-indigo-600 rounded-full text-sm font-medium"
                    >
                      {skill}
                    </motion.span>
                  ))}
                </div>
              </div>
            </motion.div>
          </AnimatePresence>

          <ArrowButton direction="left" onClick={() => paginate(-1)} />
          <ArrowButton direction="right" onClick={() => paginate(1)} />

          {/* Modal for expanded details */}
          <AnimatePresence>
            {isModalOpen && selectedExperience && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                onClick={closeModal}
              >
                <motion.div
                  className="bg-white rounded-lg p-8 max-w-2xl mx-auto"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-2xl font-bold text-indigo-600 mb-4">
                    {selectedExperience.title}
                  </h3>
                  <p className="text-gray-700 mb-6">
                    Company: {selectedExperience.company}
                  </p>
                  <ul className="text-gray-600 space-y-2">
                    {selectedExperience.description.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-full"
                    onClick={closeModal}
                  >
                    Close
                  </motion.button>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default Experience;
