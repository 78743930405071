import { Amplify } from 'aws-amplify';
import { motion } from 'framer-motion';
import React from 'react';
import { Element } from 'react-scroll'; // Import Element for section targets
import './App.css';
import config from './aws-exports';
import Contact from './components/Contact';
import Experience from './components/Experience';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Skills from './components/Skills';

Amplify.configure(config);

const App = () => {
  // Animation variant for fade-in effect
  const fadeInVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: 'easeOut' },
    },
  };

  return (
    <div className="App">
      <Navbar />
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
        transition={{ staggerChildren: 0.2 }}
      >
        {/* Wrapping each section in <Element> allows smooth scrolling */}
        <Element name="home">
          <Hero />
        </Element>

        <motion.div variants={fadeInVariants}>
          <Element name="skills">
            <Skills />
          </Element>
        </motion.div>

        <motion.div variants={fadeInVariants}>
          <Element name="projects">
            <Projects />
          </Element>
        </motion.div>

        <motion.div variants={fadeInVariants}>
          <Element name="experience">
            <Experience />
          </Element>
        </motion.div>
        <div className="mt-40 mb-10">
          <motion.div variants={fadeInVariants}>
            <Element name="contact">
              <Contact />
            </Element>
          </motion.div>
        </div>

        <Footer />
      </motion.div>
      {/* Chatbox Component */}
    </div>
  );
};

export default App;
