import { motion } from "framer-motion";
import { useState } from "react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa"; // Icons for validation feedback
import emailjs from "emailjs-com"; // Import EmailJS

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // Validate inputs in real-time
  const validateField = (name, value) => {
    switch (name) {
      case "name":
        if (value.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: "Name is required.",
          }));
        } else {
          setErrors((prevErrors) => {
            const { name, ...rest } = prevErrors;
            return rest;
          });
        }
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Invalid email address.",
          }));
        } else {
          setErrors((prevErrors) => {
            const { email, ...rest } = prevErrors;
            return rest;
          });
        }
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name, value); // Validate field on each change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    // Prepare the data to send to EmailJS
    const emailData = {
      from_name: formData.name, // Sender's name
      to_name: "Dunieski Otano", // Replace with the recipient's name
      message: formData.message,
      email: formData.email, // Sender's email
    };

    // Send the email using EmailJS
    try {
      await emailjs.send(
        "service_ogcdiw4",
        "template_4r1zq4g",
        emailData, // Use the prepared email data
        "iwndjNc8-EVVMTnZu"
      );
      setSubmitted(true);
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <section
      id="contact"
      className="py-20 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 animate-gradient bg-[length:200%_200%]"
    >
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <motion.div className="text-center mb-10">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Get in Touch
          </h2>
          <p className="text-lg text-gray-100">
            Have a question or want to work together?
          </p>
        </motion.div>

        <div className="max-w-lg mx-auto">
          <motion.div className="bg-white rounded-3xl shadow-2xl p-10">
            {submitted ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                className="text-center py-8"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, rotate: 360 }}
                  transition={{ duration: 0.6, ease: "easeOut" }}
                  className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4"
                >
                  <FaCheckCircle className="w-8 h-8 text-green-500" />
                </motion.div>
                <h3 className="text-2xl font-semibold text-gray-900 mb-2">
                  Thank you!
                </h3>
                <p className="text-gray-600">
                  Your message has been sent successfully.
                </p>
                <button
                  onClick={() => setSubmitted(false)}
                  className="mt-6 px-6 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-full shadow-lg hover:shadow-2xl transition duration-200"
                >
                  Send another message
                </button>
              </motion.div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Name
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-400 focus:outline-none transition-shadow duration-200 shadow-md"
                      placeholder="Your Name"
                    />
                    {errors.name && (
                      <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-red-500">
                        <FaExclamationCircle />
                      </span>
                    )}
                  </div>
                  {errors.name && (
                    <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Email
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-400 focus:outline-none transition-shadow duration-200 shadow-md"
                      placeholder="your@email.com"
                    />
                    {errors.email ? (
                      <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-red-500">
                        <FaExclamationCircle />
                      </span>
                    ) : (
                      formData.email && (
                        <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-green-500">
                          <FaCheckCircle />
                        </span>
                      )
                    )}
                  </div>
                  {errors.email && (
                    <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    rows={4}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-400 focus:outline-none transition-shadow duration-200 shadow-md resize-none"
                    placeholder="Your message here..."
                  />
                </div>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  disabled={isSending}
                  className="w-full flex justify-center items-center px-6 py-3 text-white rounded-lg bg-gradient-to-r from-indigo-600 to-purple-600 transition duration-200 shadow-lg"
                >
                  {isSending ? (
                    <>
                      <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-3"></div>
                      Sending...
                    </>
                  ) : (
                    "Send Message"
                  )}
                </motion.button>
              </form>
            )}
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default Contact;