// components/Skills.jsx
import { motion } from "framer-motion";
import { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Skills = () => {
  const [selectedSkill, setSelectedSkill] = useState(null);

  const skills = [
    {
      category: "Frontend",
      items: [
        {
          name: "React",
          level: 90,
          expertise: "Advanced",
          icon: "🔵",
          description: "Extensive experience with React components and hooks.",
        },
        {
          name: "JavaScript",
          level: 85,
          expertise: "Advanced",
          icon: "💛",
          description:
            "Proficient in ES6+ syntax and asynchronous programming.",
        },
        {
          name: "TypeScript",
          level: 80,
          expertise: "Intermediate",
          icon: "🔷",
          description: "Solid knowledge of TypeScript for type safety.",
        },
        {
          name: "HTML/CSS",
          level: 90,
          expertise: "Advanced",
          icon: "🎨",
          description:
            "Strong ability to create responsive layouts and animations.",
        },
      ],
    },
    {
      category: "Backend",
      items: [
        {
          name: "Node.js",
          level: 85,
          expertise: "Advanced",
          icon: "💚",
          description:
            "Building and deploying efficient server-side applications.",
        },
        {
          name: "Python",
          level: 80,
          expertise: "Intermediate",
          icon: "🐍",
          description:
            "Used for data processing, scripting, and backend services.",
        },
        {
          name: "Java",
          level: 75,
          expertise: "Intermediate",
          icon: "☕",
          description:
            "Experience in object-oriented programming and Spring Boot.",
        },
        {
          name: "SQL",
          level: 85,
          expertise: "Advanced",
          icon: "📊",
          description: "Skilled in database management and query optimization.",
        },
      ],
    },
    {
      category: "Tools & Others",
      items: [
        {
          name: "Git",
          level: 90,
          expertise: "Advanced",
          icon: "📚",
          description:
            "Proficient in version control and collaborative workflows.",
        },
        {
          name: "Docker",
          level: 80,
          expertise: "Intermediate",
          icon: "🐋",
          description: "Containerization for development and deployment.",
        },
        {
          name: "AWS",
          level: 75,
          expertise: "Intermediate",
          icon: "☁️",
          description: "Experience with cloud infrastructure and services.",
        },
        {
          name: "GraphQL",
          level: 70,
          expertise: "Intermediate",
          icon: "📱",
          description: "API development for efficient data fetching.",
        },
      ],
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const categoryVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15,
      },
    },
  };

  return (
    <section id="skills" className="py-20 bg-gray-50">
      <motion.div
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={containerVariants}
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-4xl font-bold text-center text-gray-900 mb-16"
        >
          Professional Skills
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {skills.map((category) => (
            <motion.div
              key={category.category}
              variants={categoryVariants}
              className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <motion.h3
                className="text-2xl font-semibold text-indigo-600 mb-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                {category.category}
              </motion.h3>

              <motion.div className="space-y-6">
                {category.items.map((skill) => (
                  <motion.div
                    key={skill.name}
                    whileHover={{ scale: 1.05 }}
                    className="relative flex items-center space-x-4"
                    onMouseEnter={() => setSelectedSkill(skill.name)}
                    onMouseLeave={() => setSelectedSkill(null)}
                  >
                    <div className="w-16 h-16">
                      <CircularProgressbar
                        value={skill.level}
                        text={`${skill.level}%`}
                        styles={buildStyles({
                          textSize: "24px",
                          pathColor: "#6366F1",
                          textColor: "#6366F1",
                        })}
                      />
                    </div>
                    <div>
                      <div className="flex items-center space-x-2">
                        <span className="text-xl">{skill.icon}</span>
                        <span className="font-medium text-gray-700">
                          {skill.name}
                        </span>
                        <span className="text-sm bg-gray-200 px-2 py-1 rounded-full text-gray-600">
                          {skill.expertise}
                        </span>
                      </div>

                      {/* Tooltip for additional details */}
                      {selectedSkill === skill.name && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          className="absolute left-20 bg-white border rounded-lg shadow-lg p-2 text-gray-600 text-sm w-64 mt-2"
                        >
                          {skill.description}
                        </motion.div>
                      )}
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Skills;