// components/Navbar.jsx
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("home");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    // Handle scroll events for shrinking effect and active section highlighting
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);

      const sections = ["home", "skills", "experience", "projects", "contact"];
      const currentSection = sections.find((section) => {
        const el = document.getElementById(section);
        return el && el.getBoundingClientRect().top <= 100;
      });
      if (currentSection) setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-300 ${
        scrolled
          ? "bg-white shadow-lg py-2"
          : "bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 py-4"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        {/* Logo Section */}
        <div className="flex items-center justify-center text-white md:justify-start w-full">
          <span
            className={`${
              scrolled ? "text-gray-800" : "text-white"
            } text-2xl md:text-4xl font-semibold transition-all cursor-pointer`}
          >
            Dunieski Otano
          </span>
        </div>

        {/* Desktop Links */}
        <div className="hidden md:flex items-center space-x-8">
          {["Home", "Skills", "Experience", "Projects", "Contact"].map(
            (section) => (
              <a
                key={section}
                href={`#${section.toLowerCase()}`}
                className={`${
                  activeSection === section.toLowerCase()
                    ? "text-indigo-300 font-semibold"
                    : scrolled
                    ? "text-gray-800"
                    : "text-white"
                } font-medium hover:text-indigo-300 transition-all duration-200 relative group`}
              >
                {section}
                {/* Animated underline on hover */}
                <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-indigo-300 scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-200"></span>
              </a>
            )
          )}
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className={`${
              scrolled ? "text-gray-800" : "text-white"
            }`}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={
                  isMobileMenuOpen
                    ? "M6 18L18 6M6 6l12 12"
                    : "M4 6h16M4 12h16M4 18h16"
                }
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="md:hidden bg-white shadow-lg absolute w-full top-full z-40"
          >
            <div className="flex flex-col p-4 space-y-2">
              {["Home", "Skills", "Experience", "Projects", "Contact"].map(
                (section) => (
                  <a
                    key={section}
                    href={`#${section.toLowerCase()}`}
                    onClick={() => setIsMobileMenuOpen(false)}
                    className={`${
                      activeSection === section.toLowerCase()
                        ? "text-indigo-600 font-semibold"
                        : "text-gray-700"
                    } hover:text-indigo-500 transition-colors`}
                  >
                    {section}
                  </a>
                )
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}