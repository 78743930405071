import { motion } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';
import './Hero.css';
import Jacket from './images/jacket-boy.png';

const Hero = () => {
  // Animation variants for the floating shape
  const shapeVariants = {
    initial: { opacity: 0.3, y: -50 },
    animate: {
      y: [0, -30, 0],
      opacity: [0.3, 0.4, 0.3],
      transition: {
        duration: 8,
        repeat: Infinity,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      id="home"
      className="relative pt-24 pb-16 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 overflow-hidden"
    >
      {/* Particle Animation */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-1 h-1 bg-white rounded-full opacity-20"
            initial={{
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
            }}
            animate={{
              y: ['0%', '100%'],
              opacity: [0.4, 0],
            }}
            transition={{
              duration: Math.random() * 6 + 4,
              repeat: Infinity,
              delay: Math.random() * 4,
            }}
          />
        ))}
      </div>

      {/* Floating Gradient Shape */}
      <motion.div
        variants={shapeVariants}
        className="absolute -top-16 left-1/2 w-96 h-96 bg-gradient-to-r from-indigo-300 to-purple-300 rounded-full opacity-20 -z-10"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="mb-8"
          >
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="inline-block p-3 rounded-full bg-white/10 backdrop-blur-sm"
            >
              <div className="w-40 h-40 rounded-full overflow-hidden">
                <img
                  src={Jacket}
                  alt="Profile"
                  className="w-full h-full object-cover object-center"
                />
              </div>
            </motion.div>
          </motion.div>

          <TypeAnimation
            sequence={['AWS Solutions Architect']}
            speed={70}
            wrapper="span"
            className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl" // Increase font size and set color to white
            repeat={0}
          />

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.5 }}
            className="mt-3 max-w-md mx-auto text-base text-indigo-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            Building scalable and resilient enterprise solutions with 10+ years
            of experience in cloud architecture and system design.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2 }}
            className="mt-8 flex justify-center space-x-4"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 md:py-4 md:text-lg md:px-10"
            >
              View Portfolio
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
            >
              Contact Me
            </motion.button>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1 }}
            className="mt-8"
          >
            <motion.div
              animate={{ y: [0, -10, 0] }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: 'reverse',
              }}
              className="text-white"
            >
              <svg
                className="w-6 h-6 mx-auto"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Hero;
